import $ from 'jquery';

export default function () {
  const $trigger = $('.enlarge--block');
  // const $enlargeBlocks = $('.enlarge--block');
  function triggerCheck() {
    const less = $trigger.find('.js-enlarge__trigger').data('less');
    const more = $trigger.find('.js-enlarge__trigger').data('more');
    $('.enlarge--block').find('.js-enlarge__trigger').html(`
      ${more} <span>+</span>
    `);
    $('.enlarge--block.open').find('.js-enlarge__trigger').html(`
    ${less} <span>-</span>
    `);
  }
  triggerCheck();

  $trigger.on('click', function trigger() {
    // e.preventDefault();
    // this.closest('.enlarge--block').find('.enlarge-body').slideToggle();
    $(this).closest('.enlarge--block').find('.enlarge-body').slideToggle('slow');
    $(this).closest('.enlarge--block').toggleClass('open');
    triggerCheck();
  });
}
