import $ from 'jquery';

export default function () {
  $('.slider-trip__image').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: true,
    infinite: true,
    draggable: false,
    swipe: false,
    fade: true,
    swipeToSlide: false,
    touchMove: false,
    speed: 300,
    useTransform: true,
    cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
  });
  // eslint-disable-next-line no-unused-vars
  $('.slider-trip__nav').on('init', (event, slick) => {
    $('.slider-trip__nav .slick-slide.slick-current').addClass('is-active');
  }).slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    prevArrow: $('.js-prev-item'),
    nextArrow: $('.js-next-item'),
    verticalSwiping: true,
    focusOnSelect: true,
    infinite: true,
    vertical: true,
    centerPadding: 0,
    centerMode: true,
    speed: 300,
    useTransform: true,
    cssEase: 'ease-out',
  });

  $('.slider-trip__image').on('afterChange', (event, slick, currentSlide) => {
    $('.slider-trip__nav').slick('slickGoTo', currentSlide);
    const currrentNavSlideElem = `.slider-trip__nav .slick-slide[data-slick-index="${currentSlide}"]`;
    $('.slider-trip__nav .slick-slide.is-active').removeClass('is-active');
    $(currrentNavSlideElem).addClass('is-active');
  });

  // eslint-disable-next-line func-names
  $('.slider-trip__nav').on('click', '.slick-slide', function (event) {
    event.preventDefault();
    const goToSingleSlide = $(this).data('slick-index');

    $('.slider-trip__image').slick('slickGoTo', goToSingleSlide);
  });

  $('.slider-trip__nav').on('afterChange', (event, slick, currentSlide) => {
    $('.slider-trip__image').slick('slickGoTo', currentSlide);
    const currrentNavSlideElem = `.slider-trip__image .slick-slide[data-slick-index="${currentSlide}"]`;
    $('.slider-trip__image .slick-slide.is-active').removeClass('is-active');
    $(currrentNavSlideElem).addClass('is-active');
  });

  $('.js-enlarge-image').modaal({
    type: 'image',
  });
}
